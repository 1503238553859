@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&family=Space+Mono&display=swap');

body {
  height: 100vh;
  overscroll-behavior-y: none;
  margin: 0;
  font-family: 'Quicksand', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Space Mono', monospace!important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}